<template>
  <CModal :show.sync="show" centered :close-on-backdrop="false" class="modal-tutorial">
    <template #header-wrapper>
      <div class="d-none"></div>
    </template>
    <div>
      <SwiperSlider
          ref="swiper"
          :slides-per-view="1"
          :slides-per-group="1"
          :simulate-touch="false"
          :has-pagination="true"
          @slide-change="onSlideChange">
        <template>
          <div v-for="slide in slides" :key="slide.index" class="swiper-slide">
            <div class="text-center">
              <CImg :src="slide.image" class="img w-100" fluid/>
              <div class="">
                <h3 class="title">{{ slide.title }}</h3>
                <p class="descr" v-html="slide.description"></p>
              </div>
            </div>
          </div>
        </template>
      </SwiperSlider>
    </div>
    <template #footer>
      <div class="d-flex w-100">
        <div class="flex-grow-1 mr-1" v-if="slides.length > 1">
          <CButton block @click="close"
                   color="secondary"
                   v-translate translate-context="event_wizard.tutorial">Skip</CButton>
        </div>
        <div class="flex-grow-1 text-center ml-1">
          <CButton block
                   @click="onNext"
                   color="primary">
            <span v-translate translate-context="event_wizard.tutorial" v-if="slides.length > 1">Next</span>
            <span v-translate translate-context="event_wizard.tutorial" v-else>Continue</span>
          </CButton>
        </div>
      </div>
    </template>
  </CModal>
</template>

<script>

import SwiperSlider from "@/domain/core/components/SwiperSlider.vue";

export default {
  name: "GalleryTutorial",
  components: {SwiperSlider},
  emits: ['completed'],
  data() {
    return {
      show: false,
    }
  },
  props: {
    gallery: {
      type: Object,
      required: true
    }
  },
  computed: {
    slides() {
      const slides = {
        1: {
          title: this.defaultTitle,
          description: this.gallery.description || this.defaultDescription,
          image: this.defaultImage
        }
      }
      return Object.keys(slides).map(key => {
        return {
          index: key,
          ...slides[key]
        }
      })
    },
    defaultTitle() {
      if (this.gallery.is_public) {
        return this.$gettextInterpolate(
            this.$pgettext('event_wizard.tutorial', '"%{galleryName}" gallery'),
            {galleryName: this.gallery.name}
          )
      } else {
         return this.$pgettext('event_wizard.tutorial', 'Photo gallery')
      }

    },
    defaultDescription() {
      if (this.gallery.is_public) {
        return this.$pgettext('event_wizard.tutorial', 'Upload up to 20 items to this gallery to make them visible in the albums of all the participants.<br>Check the preview on the side to see how they will be displayed to participants.')
      } else {
        return this.$pgettext('event_wizard.tutorial', 'Upload all event photos to be delivered to participants to this gallery. Each person will only receive and view the photos they appear in.')
      }
    },
    defaultImage() {
      if (this.gallery.is_public) {
        return require(`@/assets/images/gallery/tutorial/tutorial-public-gallery.png`)
      }
      return require(`@/assets/images/gallery/tutorial/tutorial-private-gallery.png`)
    }
  },
  methods: {
    open() {
      this.show = true
      this.$refs.swiper.instance.slideTo(0)
    },
    close() {
      this.show = false
      this.$emit('completed')
    },
    onNext() {
      if (this.$refs.swiper.instance.isEnd) {
        this.show = false
        this.$emit('completed')
        return
      }
      this.$refs.swiper.instance.slideNext()
    },
    onSlideChange() {
      if (this.$refs.swiper.instance.isEnd) {
        this.$emit('completed')
      }
    }
  }
}
</script>
