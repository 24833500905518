<template>
  <div class="event-wizard__footer" :class="{'fluid': fluid}">
    <CRow>
      <CCol>
        <div class="d-flex justify-content-end">
          <div class="mr-2">
            <CButton color="secondary" block @click="$emit('reset')" ref="reset-button"
                     v-translate translate-context="event_wizard">
              Reset
            </CButton>
          </div>
          <div>
            <CButton color="primary" @click="$emit('save')" block ref="save-button"
                     v-translate translate-context="event_wizard">
              Save
            </CButton>
          </div>

        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "TabFooter",
  emits: ['save', 'reset'],
  props: {
    fluid: {
      type: Boolean,
      default: false
    }
  }
}
</script>