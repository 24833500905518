<template>
  <div v-if="!loading">
    <template v-if="hasPerm('gallery.edit_price_list')">
      <template v-if="advanced">
        <CRow class="">
          <CCol class="mb-3">
            <p class="text-help-dark" v-translate translate-context="event_wizard.subtitle">
              Create one or more price list for this event</p>
            <CButton color="primary" @click="addPriceList">
              <CIcon name="cipPlus"/>
              <translate translate-context="pricelist.actions">Add Price List</translate>
            </CButton>
          </CCol>
        </CRow>
      </template>
      <template v-if="multiplePriceList">
        <CRow style="padding-bottom: 140px">
          <CCol>
            <draggable v-model="priceLists" handle=".handle" ghost-class="ghost">
              <transition-group type="transition" :name="'flip-list'">
                <div class="accordion-list__item" v-for="priceList in priceLists" :key="priceList.id">
                  <PriceListHeader
                      :data="priceList"
                      :active="activePriceList === priceList.id"
                      :advanced="advanced"
                      @delete="onDeletePriceList(priceList)"
                      @edit="onEditPriceList(priceList)"
                  />
                  <PriceListOpened
                      ref="priceList"
                      :data="priceList"
                      :isSubGallery="isSubGallery"
                      :advanced="advanced"
                      v-if="activePriceList === priceList.id"
                      @preview="onPreviewPriceList"
                  />
                </div>
              </transition-group>
            </draggable>
          </CCol>
        </CRow>
      </template>
      <template v-else>
        <PriceListOpened
            :data="priceLists[0]"
            :isSubGallery="isSubGallery"
            :advanced="advanced"
            @preview="onPreviewPriceList"
            ref="priceList"
        />
      </template>
      <TabFooter @save="onSave" @reset="onReset"/>
    </template>
    <template v-else>
      You can preview how the price list will look like for the end user using the right preview panel
    </template>
  </div>
</template>

<script>
import {v4 as uuid} from "uuid";
import {cloneDeep} from "lodash";
import draggable from 'vuedraggable'
import PriceListOpened from "@/domain/eventGallery/tab/priceList/PriceListOpened.vue";
import TabFooter from "@/domain/eventGallery/components/TabFooter.vue";
import PriceListHeader from "@/domain/eventGallery/tab/priceList/PriceListHeader.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TabPriceList",
  components: {PriceListHeader, TabFooter, PriceListOpened, draggable},
  emits: ['save', 'preview'],
  props: {
    data: {
      type: Object,
      required: true
    },
    advanced: {
      type: Boolean,
      required: true
    },
    isSubGallery: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      priceLists: [],
      activePriceList: null,
      newPriceList: {
        valid_from: '',
        valid_to: '',
        configuration: {},
        items: [],
        allow_preorder: false,
        allow_upsell: false,
        cta_enabled: false,
        cta_min_qty: 0,
        cta_position: 'b',
      }
    }
  },
  mounted() {
    this.onReset()
  },
  computed: {
    ...mapGetters('event', [
      'eventCurrency',
      'currentEventName',
    ]),
    multiplePriceList() {
      if (this.advanced) return true
      return this.priceLists.length > 1
    }
  },
  methods: {
    ...mapActions('eventWizard', [
      'fetchEventConfiguration',
    ]),
    onReset() {
      this.loading = true
      if (!this.data.price_lists)
        this.priceLists = []
      else
        this.priceLists = cloneDeep(this.data.price_lists).sort((a, b) => a.order - b.order)
      if (this.priceLists.length <= 0)
        this.addPriceList()
      if (!this.activePriceList || !this.priceLists.find(p => p.id === this.activePriceList))
        this.activePriceList = this.priceLists[0].id
      this.loading = false
      this.updatePreview()
    },
    addPriceList() {
      this.priceLists.push({
        id: uuid(),
        isNew: true,
        order: this.priceLists.length + 1,
        ...this.newPriceList
      })
      this.activePriceList = this.priceLists[this.priceLists.length - 1].id
      this.updatePreview()
    },
    onDeletePriceList(priceList) {
      this.priceLists = this.priceLists.filter(p => p.id !== priceList.id)
    },
    onEditPriceList(priceList) {
      if (this.activePriceList === priceList.id)
        this.activePriceList = null
      else
        this.activePriceList = priceList.id
      this.updatePreview()
    },
    async onSave() {
      this.updateSorting()
      this.forceSavePriceList()
      await this.emitPromised('save', {
        price_lists: this.priceLists.map(priceList => {
          // The id is valid and should be kept only if it's a "valid" id, and not a "fake" uuid used only for differentiate element in the loop
          if (!priceList.valid_from) priceList.valid_from = null
          if (!priceList.valid_to) priceList.valid_to = null
          return {
            ...priceList,
            id: priceList.isNew ? null : priceList.id,
            items: priceList.items ?? [],
          }
        }) // TO REVIEW -> not works with PACK  .filter(priceList => priceList.items.length >= 0)
      })
      await this.fetchEventConfiguration()
      this.onReset()
    },
    forceSavePriceList() {
      let price = null
      for (let i = 0; i < this.priceLists.length; i++) {
        if (!this.priceLists[i]) continue
        price = this.$refs.priceList[0].getPriceList()
      }

      this.priceLists = this.priceLists.map(p => {
        if (p.id === this.activePriceList)
          return price
        return p
      })
    },
    onPreviewPriceList(priceList) {
      this.$emit('preview', {
        items: priceList.items,
        currency: this.eventCurrency,
        event_name: this.currentEventName,
      })
    },
    updateSorting() {
      this.priceLists = this.priceLists.map((priceList, index) => {
        priceList.order = index
        return priceList
      })
    },
    updatePreview() {
      const active = this.priceLists.find(p => p.id === this.activePriceList)
      if (!active) return this.$emit('preview', {})
      this.$emit('preview', {
        items: active.items,
        currency: this.eventCurrency,
        event_name: this.currentEventName,
      })
    }
  }
}
</script>