import {translate} from "vue-gettext";

const {gettext: $gettext} = translate;

export default {
    horizontal: 'h',
    vertical: 'v',

    default() {
        return this.horizontal
    },

    options() {
        return [
            {
                id: this.horizontal,
                name: $gettext('Horizontal')
            },
            {
                id: this.vertical,
                name: $gettext('Vertical')
            },
        ]
    }
}