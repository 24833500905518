<template>
  <div class="event-wizard">
    <CContainer>
      <h5 class="" v-translate translate-context="event_gallery.utility">Utility</h5>
      <p class="text-help-dark">
        <translate translate-context="event_gallery.utility">Additional features for your event</translate>
      </p>
      <CRow>
        <CCol col="12">
          <PFRow half-width
                 separator
                 label-class="big"
                 :label="$pgettext('event_gallery.utility', 'Photo delivery report')">
            <template #help-text>
              <div class="my-1" v-translate>Click to export a single .csv file with the names of the individual photos and information on how many people that photo can be delivered to and how many people it was actually delivered to
              </div>
            </template>
            <div class="text-right">
               <PButton color="secondary" size="sm" v-if="hasPerm('utility.export_media_pica_code')"
                     @click="exportMediaPicaCode">
              <translate translate-context="event_gallery.utility">Export</translate>
                 <CIcon name="cipDownload03" size="custom" :height="15" class="ml-2"/>
            </PButton>
            </div>
          </PFRow>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>


<script>
import PFRow from "@/domain/core/components/PFRow.vue";
import {mapActions} from "vuex";
import PButton from "@/components/PButton.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";

export default {
  name: "TabUtility",
  components: {PSelectKeyValue, PButton, PFRow},
  data() {
    return {}
  },
  async mounted() {
    this.setPicaServiceId({
      picaServiceId: this.$route.params.picaServiceId,
      galleryId: this.$route.params.galleryId
    })
  },
  methods: {
    ...mapActions('photoSearch', [
      'exportMediaPicaCode',
      'setPicaServiceId'
    ]),
  }
}
</script>