<template>
  <div :class="{'accordion-list__item-content': advanced}">
    <h5 class="" v-translate translate-context="event_gallery">Price list</h5>
    <p class="text-help-dark">
      <translate translate-context="event_gallery.subtitle">Define the prices of your photos for sale</translate>
    </p>
    <template v-if="advanced">
      <CRow>
        <CCol lg="7">
          <h6 class="font-weight-semi-bold">{{ $pgettext('event_wizard.price_list.form', 'Display name') }}</h6>
          <CInput v-model="form.display_name" @input="onPreview"/>
        </CCol>
        <CCol lg="5">
          <h6 class="font-weight-semi-bold">{{ $pgettext('event_wizard.price_list.form', 'Validity Period') }}</h6>
          <VueCtkDateTimePicker
              v-model="validity"
              :no-label="true"
              :label="$gettext('Select date')"
              format="YYYY-MM-DD HH:mm:ss"
              formatted="LL"
              range
              :right="true"
              :only-date="true"
              :no-button-now="true"
              color="#1867FF"
              @input="onPreview"
          />
        </CCol>
      </CRow>
      <CRow class="justify-content-between" align-horizontal="">
        <CCol col="12">
          <hr>
        </CCol>
        <CCol lg="auto">
          <h6 class="font-weight-semi-bold">{{ $pgettext('event_wizard.price_list.form', 'Preorder') }}</h6>
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allow_preorder"
                   @update:checked="onPreview"/>
        </CCol>
        <CCol lg="auto">
          <h6 class="font-weight-semi-bold">{{ $pgettext('event_wizard.price_list.form', 'Upsell') }}</h6>
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allow_upsell"
                   @update:checked="onPreview"/>
        </CCol>
        <CCol lg="auto">
          <h6 class="font-weight-semi-bold">{{ $pgettext('event_wizard.price_list.form', 'Call to action') }}</h6>
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.cta_enabled"
                   @update:checked="onPreview"/>
        </CCol>
        <template v-if="form.cta_enabled">
          <CCol col="12" class="mt-3">
            <h6 class="font-weight-semi-bold">{{
                $pgettext('event_wizard.price_list.form', 'Call to action settings')
              }}</h6>
            <CRow>
              <CCol lg="6">
                <label>{{ $pgettext('event_wizard.price_list.form', 'Show after') }}</label>
                <CInput v-model="form.cta_min_qty" type="number" size="sm" @input="onPreview"/>
              </CCol>
              <CCol lg="6">
                <label>{{ $pgettext('event_wizard.price_list.form', 'Position') }}</label>
                <PSelectKeyValue :options="ctaPositions" :value.sync="form.cta_position" @input="onPreview"/>
              </CCol>
            </CRow>
          </CCol>
        </template>
      </CRow>
    </template>
    <PFRow half-width :label="$pgettext('event_wizard.price_list.form', 'Price list type')"
           label-class="big"
           separator>
      <template #help-text>
        <div class="my-1" v-translate>Choose the sales method</div>
        <ul class="pl-3">
          <li v-translate>Per Package: Choose how many photos make up a complete package and the total price of the
            package. Single photo prices are calculated automatically
          </li>
          <li v-translate>Per unit: choose the selling price of each individual photo</li>
        </ul>
      </template>
      <PSelectKeyValue :options="priceListOptions" :value="form.configuration.type" @input="onChangeLayoutType"/>
    </PFRow>

    <PackPriceList
        :currency="eventCurrency"
        @update="onPreviewPrice"
        ref="prices"
        v-if="form.configuration.type === priceListType.pack"
    />
    <UnitPriceList
        :currency="eventCurrency"
        @update="onPreviewPrice"
        ref="prices"
        v-if="form.configuration.type === priceListType.unit"
    />
    <CustomPriceList
        :currency="eventCurrency"
        @update="onPreviewPrice"
        ref="prices"
        v-if="form.configuration.type === priceListType.custom"
    />
    <ParentPriceList
        ref="prices"
        @update="onPreviewPrice"
        v-if="form.configuration.type === priceListType.parent"
    />
    <TemplatePriceList
        ref="prices"
        @update="onPreviewPrice"
        v-if="form.configuration.type === priceListType.template"
    />
  </div>
</template>

<script>

import 'vue2-timepicker/dist/VueTimepicker.css'
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import CustomPriceList from "@/domain/eventGallery/tab/priceList/CustomPriceList.vue";
import priceListType from "@/domain/core/constant/priceListType";
import PackPriceList from "@/domain/eventGallery/tab/priceList/PackPriceList.vue";
import UnitPriceList from "@/domain/eventGallery/tab/priceList/UnitPriceList.vue";
import PFRow from "@/domain/core/components/PFRow.vue";
import ParentPriceList from "@/domain/eventGallery/tab/priceList/ParentPriceList.vue";
import {mapGetters} from "vuex";
import TemplatePriceList from "@/domain/eventGallery/tab/priceList/TemplatePriceList.vue";

export default {
  name: "PriceListOpened",
  emits: ['update', 'preview'],
  components: {
    TemplatePriceList,
    ParentPriceList,
    PFRow,
    UnitPriceList,
    PackPriceList,
    CustomPriceList,
    PSelectKeyValue
  },
  data() {
    return {
      validity: {
        start: null,
        end: null,
      },
      form: {
        items: [],
        configuration: {},
        valid_from: null,
        valid_to: null,
        allow_preorder: false,
        allow_upsell: false,
        cta_enabled: false,
        cta_min_qty: 0,
        cta_position: 'b',
        display_name: '',
      },
      ctaPositions: [
        {id: 'b', name: this.$pgettext('event_wizard.price_list.form.label', 'Bottom')},
        {id: 't', name: this.$pgettext('event_wizard.price_list.form.label', 'Top')},
      ],
    }
  },
  watch: {
    data: function () {
      this.onChangeData()
    }
  },
  mounted() {
    this.onChangeData()
  },
  computed: {
    ...mapGetters('event', ['eventCurrency']),
    priceListType() {
      return priceListType
    },
    priceListOptions() {
      return priceListType.options(this.advanced, this.isSubGallery, this.advanced)
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    advanced: {
      type: Boolean,
      required: true
    },
    isSubGallery: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    getPriceList() {
      const priceList = this.$refs.prices.getPriceList()
      this.form.items = priceList.items
      this.form.configuration = {
        ...priceList.configuration,
        type: this.form.configuration.type
      }
      return {
        ...this.form,
        valid_from: this.validity.start,
        valid_to: this.validity.end,
      }
    },
    async onChangeData() {
      this.form = {
        ...this.data,
      }
      if (!this.form.configuration)
        this.form.configuration = {type: priceListType.default(this.isSubGallery)}
      if (!this.form.configuration.type)
        this.form.configuration.type = priceListType.default(this.isSubGallery)

      this.validity = {
        start: this.data.valid_from,
        end: this.data.valid_to,
      }

      await this.initPrices()
    },
    onPreviewPrice({items, configuration}) {
      this.form.items = items
      this.form.configuration = {
        ...configuration,
        type: this.form.configuration.type
      }
      this.onPreview()
    },
    async onPreview() {
      this.$emit('preview', {
        ...this.form,
        valid_from: this.validity.start,
        valid_to: this.validity.end,
      })
    },
    async onChangeLayoutType(newTypeObj) {
      const newType = newTypeObj.value
      if (newType === priceListType.custom) {
        this.form.configuration = {
          type: newType
        }
        return await this.initPrices(true)
      }
      let confirmMsg = this.$gettext('Changing the layout will reset every unsaved parameters, are you sure?')
      let isEmpty = !this.form.items || this.form.items.length === 0
      if (isEmpty || confirm(confirmMsg)) {
        this.form.items = []
        this.form.configuration = {
          type: newType
        }
        return await this.initPrices(true)
      }
    },
    async initPrices(onChange = false) {
      await this.$nextTick()
      await this.delay(100)
      if (!this.$refs.prices) return
      this.$refs.prices.init({
        items: this.form.items,
        configuration: this.form.configuration,
        onChange: onChange
      })
    }
  }
}
</script>