<template>
  <div :class="{'sidebar-right--hidden': !sidebar, 'd-xl-flex': tabHasSidebar}" v-if="gallery">
    <div class="c-body">
      <main class="c-main">
        <div :class="{'event-wizard': tabHasSidebar}">

          <div class="d-flex align-items-baseline mb-2">
            <h1 class="main-header d-flex font-weight-semi-bold mb-0">
              <CIcon size="custom-size" :height="24" :name="gallery.is_public ? 'cipFolderBlueStar' : 'cipFolderBlue'"
                     class="mr-3"/>
              <span>{{ gallery.name }}</span>
              <CLink @click="openTutorial" class="ml-2 text-secondary">
                <CIcon size="custom-size" :height="18" name="cipCircleHelp"/>
              </CLink>
            </h1>
            <div class="ml-3" v-if="galleryStats && galleryStats.received_count > 0">
              {{ galleryStats.received_count }} media
            </div>
          </div>

          <CContainer>
            <div class="d-flex justify-content-end event-wizard-adv w-100"
                 v-if="showAdvancedFlag">
              <div class="d-flex align-items-end justify-content-end align-items-center">
                <label class="m-0">{{ $pgettext('event_template.label', 'Advanced') }}</label>
                <CSwitch class="ml-2 mt-2" color="primary"
                         variant="3d" size="sm"
                         :checked="advanced(tab)"
                         @update:checked="setAdvanced({value:!advanced(tab),type:tab})"
                />
              </div>
            </div>
          </CContainer>

          <PTabs :tabs="tabs" :selected="tab" @select="goToTab"/>
            <div v-if="!loading">
              <TabSetting
                  v-if="tab === 'setting' && hasPerm('gallery.settings')"
                  :data="step_setting"
                  :isDeletable="isGalleryDeletable"
                  :advanced="advanced('setting')"
                  @save="saveStep"
                  @delete="onDeleteGallery(gallery)"
              />
              <TabPriceList
                  v-if="tab === 'price_list' && hasPerm('gallery.price_list')"
                  :data="step_price_list"
                  :advanced="advanced('price_list')"
                  :isSubGallery="isSubGallery"
                  @save="saveStep"
                  @preview="updatePreviewPriceList"
              />
              <TabLogoOnPhoto
                  v-if="tab === 'logo_on_photo' && hasPerm('gallery.logo_on_photo')"
                  :data="step_logo_on_photo"
                  :get_upload_intent="(file) => getWizardUploadIntent({field:'logo',file:file})"
                  :get_upload_intent_public_url="getUploadIntentPublicUrl"
                  @save="saveStep"
                  @preview="updatePreviewLogoOnPhoto"
              />
              <TabBrowsePhoto v-if="tab === 'browse_photo' && hasPerm('gallery.browse_photo')"/>
              <TabUtility v-if="tab === 'utility' && hasPerm('gallery.utility')"/>
            </div>
        </div>
      </main>
    </div>
    <div class="c-sidebar-right" v-if="tabHasSidebar">
      <PreviewBar
          :overflow-hidden="!['customize_logo_on_photo'].includes(tab)"
          @toggle="onToggleSidebar">
        <PreviewPriceList :data="preview.priceList" v-if="['price_list'].includes(tab)"/>
        <PreviewLogo :data="preview.logoOnPhoto" v-if="['logo_on_photo'].includes(tab)"/>
      </PreviewBar>
    </div>
    <GalleryTutorial :gallery="gallery" ref="tutorial"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import PTabs from "@/domain/core/components/PTabs.vue";
import TabSetting from "@/domain/eventGallery/tab/TabSetting.vue";
import TabLogoOnPhoto from "@/domain/eventGallery/tab/TabLogoOnPhoto.vue";
import TabBrowsePhoto from "@/domain/eventGallery/tab/TabBrowsePhoto.vue";
import TabPriceList from "@/domain/eventGallery/tab/TabPriceList.vue";
import PreviewPriceList from "@/domain/eventGallery/preview/PreviewPriceList.vue";
import PreviewBar from "@/domain/eventWizard/preview/PreviewBar.vue";
import PreviewLogo from "@/domain/eventWizard/preview/PreviewLogo.vue";
import GalleryTutorial from "@/domain/eventGallery/components/GalleryTutorial.vue";
import MessageManager from "@/domain/core/utils/messageManager";
import mqttTopics from "@/domain/core/constant/mqttTopics";
import TabUtility from "@/domain/eventGallery/tab/TabUtility.vue";

export default {
  name: 'EventGallery',
  components: {
    TabUtility,
    GalleryTutorial,
    PreviewLogo,
    PreviewBar,
    PreviewPriceList,
    TabPriceList,
    TabBrowsePhoto,
    TabLogoOnPhoto,
    TabSetting,
    PTabs,
  },

  data() {
    return {
      loading: true,
      sidebar: true,
      preview: {
        priceList: {},
        logoOnPhoto: {},
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.onOpenGallery(to.params.picaServiceId, to.params.galleryId)
    next()
  },
  async mounted() {
    await this.onOpenGallery(this.$route.params.picaServiceId, this.$route.params.galleryId)
  },
  beforeDestroy() {
    MessageManager.off('eventGallery')
    MessageManager.setParams({galleryId: '*'})
  },
  props: {
    tab: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters('eventWizard', [
      'advanced',
    ]),
    ...mapGetters('eventGallery', [
      'gallery',
      'galleries',
      'picaServiceId',
      'step_setting',
      'step_logo_on_photo',
      'step_price_list'
    ]),
    ...mapGetters('uploader', [
      'galleryStats'
    ]),
    isSubGallery() {
      return this.gallery.parent_gallery !== null
    },
    isGalleryDeletable() {
      if (!this.hasPerm('gallery.delete')) return false
      return this.isSubGallery || this.galleries.length > 1
    },
    tabs() {
      let tabs = []
      if (this.hasPerm('gallery.browse_photo')) tabs.push({
        name: 'browse_photo',
        label: this.$pgettext('event_gallery', 'Medias'),
        icon: 'cip-picture-circle'
      })
      if (this.hasPerm('gallery.price_list')) tabs.push({
        name: 'price_list',
        label: `${this.$pgettext('event_gallery', 'Price list')} ${this.missingPriceList ? '<span class="red-dot"></span>' : ''}`,
        icon: 'cip-credit-card-plus'
      })
      if (this.hasPerm('gallery.logo_on_photo')) tabs.push({
        name: 'logo_on_photo',
        label: this.$pgettext('event_gallery', 'Photo branding'),
        icon: 'cip-layer'
      })
      if (this.hasPerm('gallery.utility')) tabs.push({
        name: 'utility',
        label: this.$pgettext('event_gallery', 'Utility'),
        icon: 'cip-tool'
      })
      if (this.hasPerm('gallery.settings')) tabs.push({
        name: 'setting',
        label: this.$pgettext('event_gallery', 'Settings'),
        icon: 'cip-cog'
      })
      return tabs
    },
    missingPriceList() {
      if (this.gallery.parent_gallery) {
        const parentGallery = this.galleries.find(gallery => gallery.id === this.gallery.parent_gallery)
        const gallery = parentGallery.sub.find(gallery => gallery.id === this.gallery.id)
        return gallery.missing_price_list
      }
      const gallery = this.galleries.find(gallery => gallery.id === this.gallery.id)
      return gallery.missing_price_list
    },
    tabHasSidebar() {
      return [
        'price_list',
        'logo_on_photo',
      ].includes(this.tab)
    },
    showAdvancedFlag() {
      if (this.tab === 'price_list')
        return this.hasPerm('advanced.price_list')
      if (this.tab === 'setting')
        return this.hasPerm('advanced.gallery_configuration')
      return false
    }
  },

  methods: {
    ...mapActions('eventGallery', [
      'setPicaServiceId',
      'fetchGallery',
      'fetchTabData',
      'updateTabData',
      'deleteGallery',
    ]),
    ...mapActions('eventWizard', [
      'getWizardUploadIntent',
      'getUploadIntentPublicUrl',
      'setAdvanced',
      'fetchEventConfiguration'
    ]),
    ...mapActions('uploader', [
      'fetchGalleryStats',
      'updateGalleryStats',
      'setDestination'
    ]),
    ...mapActions('logoSet',
        {setLogoSetPicaServiceId: 'setPicaServiceId'}
    ),

    async onOpenGallery(picaServiceId, galleryId) {
      this.loading = true
      await this.setPicaServiceId(picaServiceId)
      await this.setLogoSetPicaServiceId(picaServiceId)
      await this.fetchGallery(galleryId)

      MessageManager.setParams({galleryId: galleryId})
      await this.setDestination({
        picaServiceId: this.$route.params.picaServiceId,
        galleryId: galleryId
      })

      if (this.hasPerm('stats.counters')) {
        await this.fetchGalleryStats()
        MessageManager.on(mqttTopics.upload.gallery, async (data) => {
          await this.updateGalleryStats({
            intents: data.data.metrics.intents ?? null,
            uploaded: data.data.metrics.uploaded ?? null,
            received: data.data.metrics.received ?? null,
            processed: data.data.metrics.processed ?? null
          })
        }, 'eventGallery')
      } else {
        MessageManager.off('eventGallery')
      }

      if (this.tab == null) {
        if (!this.tabs.length)
          await this.$router.push('/')
        const tab = this.tabs[0].name
        await this.$router.push({
          name: `eventWizard.gallery.${tab}`, params: {
            galleryId: this.gallery.id,
            picaServiceId: this.picaServiceId
          }
        })
      }
      await this.fetchTabData(this.tab)

      this.loading = false
    },
    async goToTab(tab) {
      this.loading = true
      await this.$router.push({
        name: `eventWizard.gallery.${tab}`, params: {
          galleryId: this.gallery.id,
          picaServiceId: this.picaServiceId
        }
      })
      await this.fetchTabData(tab)
      this.loading = false
    },
    async saveStep(data) {
      await this.updateTabData({tab: this.tab, data})
      await this.notifySuccess(this.$pgettext('event_wizard', 'Event Updated'))
    },
    async onDeleteGallery(gallery) {
      await this.deleteGallery(gallery)
      const firstGallery = this.galleries[0]
      await this.fetchEventConfiguration()
      await this.$router.push({
        name: `eventWizard.gallery.${this.tab}`, params: {
          galleryId: firstGallery.id,
          picaServiceId: this.picaServiceId
        }
      })
    },
    onToggleSidebar(state) {
      this.sidebar = state
    },
    updatePreviewPriceList(data) {
      this.preview.priceList = data
    },
    updatePreviewLogoOnPhoto(data) {
      this.preview.logoOnPhoto = data
    },
    openTutorial() {
      this.$refs.tutorial.open()
    }
  }
}
</script>
