<template>
  <div class="accordion-list__item-header d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <CIcon :name="isValid(data) ? 'cipDotGreen' : 'cipDotOrange'" size="custom-size"
             :height="8"
             class="mr-2"/>
      <h6 class="m-0">{{ data.display_name }}</h6>
    </div>
    <div class="d-flex align-items-center">
      <div class="handle mr-3">
        <CIcon size="lg" name="cip-hamburger"/>
      </div>
      <div class="mr-3">
        <CButton class="btn-circle btn-circle--sm" color="danger" size="sm" variant="outline"
                 v-if="advanced"
                 @click="$emit('delete')">
          <CIcon name="cip-trash-full" size="custom" :width="26"/>
        </CButton>
      </div>
      <CButton class="btn-circle btn-circle--sm" size="sm" color="dark" variant="outline"
               @click="$emit('edit')">
        <CIcon :name="active ? 'cipMinus' : 'cipPlus'"/>
      </CButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceListHeader",
  props: {
    active: {
      type: Boolean,
      required: true
    },
    advanced: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    isValid(priceList) {
      if (!priceList.valid_from || !priceList.valid_to)
        return true
      const today = new Date()
      return new Date(priceList.valid_from) <= today && new Date(priceList.valid_to) >= today
    },
  }
}
</script>
