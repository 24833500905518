<template>
  <PhotoSearch :gallery="gallery"/>
</template>

<script>
import PhotoSearch from "@/domain/photoSearch/PhotoSearch.vue";
import {mapGetters} from "vuex";

export default {
  name: "TabBrowsePhoto",
  components: {PhotoSearch},
  computed: {
    ...mapGetters('eventGallery', [
      'gallery',
    ]),
  }
}
</script>
