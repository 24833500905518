<template>
  <div class="event-wizard">
    <CContainer>
      <h5 class="" v-translate translate-context="event_gallery">Settings</h5>
      <p class="text-help-dark">
        <translate translate-context="event_gallery.subtitle">Edit the gallery settings</translate>
      </p>
     <CRow>
      <CCol col="12">
        <PFRow size="7x5" :label="$pgettext('event_gallery.label', 'Name')" label-class="big" separator>
          <PTranslatableTextInput v-model="form.name"/>
        </PFRow>
        <PFRow size="7x5" :label="$pgettext('event_gallery.label', 'Free')"
               :help-text="$pgettext('event_gallery.help', 'Make the media uploaded to this gallery available for free')"
               separator
               label-class="big">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.is_free"/>
        </PFRow>
        <PFRow size="7x5"
               :label="$pgettext('event_gallery.label', 'Public')"
               label-class="big"
               separator
               :help-text="$pgettext('event_gallery.help', 'Public gallery visible to all event participants')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.is_public"/>
        </PFRow>
                <PFRow size="7x5"
               :label="$pgettext('event_gallery.label', 'Show in All Photos')"
               label-class="big"
               separator>
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.show_in_all_photos"/>
        </PFRow>
        <PFRow size="7x5"
               :label="$pgettext('event_gallery.label', 'Hide Gallery Name')"
               label-class="big"
               separator
               :help-text="$pgettext('event_gallery.help', 'Do not display in preview')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.hide_name"/>
        </PFRow>
        <PFRow size="7x5" :label="$pgettext('event_gallery.label', 'Media sorting')" label-class="big" separator>
          <PSelectKeyValue
              :options="sortingOptions"
              :value.sync="form.sorting"
              :placeholder="$pgettext('event_gallery.placeholder','Select media sort type')"
          />
        </PFRow>
        <template v-if="advanced">
          <PFRow size="7x5"
                 :label="$pgettext('event_gallery.label', 'Layout')"
                 label-class="big"
                 separator
                 :help-text="$pgettext('event_gallery.help', 'Choose how to arrange your elements in the preview')">
            <PSelectKeyValue
                :options="layoutOptions"
                :value.sync="form.layout"
                :placeholder="$pgettext('event_gallery.placeholder','Select layout type')"
            />
          </PFRow>
          <PFRow size="7x5"
                 :label="$pgettext('event_gallery.label', 'Shareable')"
                 label-class="big"
                 separator
                 :help-text="$pgettext('event_gallery.help', 'Allow users to share the gallery')">
            <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                     :checked.sync="form.is_shareable"/>
          </PFRow>
          <PFRow size="7x5" v-if="tags"
                 :label="$pgettext('event_gallery.label', 'Tags')"
                 label-class="big"
                 separator
                 :help-text="$pgettext('event_gallery.help', 'Set specific tags for this gallery')">
            <PTreeTagInput v-model="form.tags" :allowed_tags="tags" :multiple="true"/>
          </PFRow>
          <PFRow size="7x5"
                 :label="$pgettext('event_gallery.label', 'Image enhancement')"
                 label-class="big"
                 separator
                 :help-text="$pgettext('event_gallery.help', 'Improve the image quality')">
            <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                     :checked.sync="form.enhance_img"/>
          </PFRow>
          <PFRow size="7x5"
                 :label="$pgettext('event_gallery.label', 'Passive')"
                 label-class="big"
                 separator
                 :help-text="$pgettext('event_gallery.help', 'Disable browse photo/upload for this gallery, media will be received using tags/releases')">
            <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                     :checked.sync="form.passive"/>
          </PFRow>
          <PFRow size="7x5"
                 :label="$pgettext('event_gallery.label', 'Activate')"
                 label-class="big"
                 separator
                 :help-text="$pgettext('event_gallery.help', 'Turn the gallery on or off')">
            <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                     :checked.sync="form.active"/>
          </PFRow>
          <PFRow size="7x5"
                 :label="$pgettext('event_gallery.label', 'Order')"
                 label-class="big"
                 separator
                 :help-text="$pgettext('event_gallery.help', 'Order the gallery (ascending 0->100)')">
            <CInput v-model="form.order" type="number"/>
          </PFRow>
          <PFRow size="7x5" :label="$pgettext('event_gallery.label', 'Description (Dashboard only)')" label-class="big"
                 separator>
            <PTranslatableCkeditor v-model="form.description"/>
          </PFRow>
          <PFRow size="7x5"
                 :label="$pgettext('event_gallery.label', 'Max Uploads')"
                 label-class="big"
                 separator
                 :help-text="$pgettext('event_gallery.help', 'Max media for this gallery (0 = Unlimited)')">
            <CInput v-model="form.max_media" type="number"/>
          </PFRow>
        </template>
        <template v-if="isDeletable">
          <PFRow size="7x5" :help-text="$pgettext('event_gallery.help', 'This will delete the gallery permanently')"
                 separator>
            <template #title>
              <label class="big text-danger"> {{ $pgettext('event_gallery.label', 'Delete Gallery') }}</label>
            </template>
            <CButton @click="onDeleteGallery"
                     class="btn-circle"
                     color="dark"
                     variant="outline">
              <CIcon name="cipTrashFull"/>
            </CButton>
          </PFRow>
        </template>
      </CCol>
    </CRow>
      </CContainer>
    <TabFooter fluid @save="onSave" @reset="onReset"/>
  </div>
</template>

<script>
import PFRow from "@/domain/core/components/PFRow.vue";
import PTranslatableTextInput from "@/domain/core/components/PTranslatableTextInput.vue";
import PTreeTagInput from "@/domain/core/components/PTreeTagInput.vue";
import TabFooter from "@/domain/eventGallery/components/TabFooter.vue";
import galleryLayoutTypes from "@/domain/core/constant/galleryLayoutTypes";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import gallerySorting from "@/domain/core/constant/gallerySorting";
import {mapActions, mapGetters} from "vuex";
import PTranslatableCkeditor from "@/domain/core/components/PTranslatableCkeditor.vue";

export default {
  name: "TabSetting",
  components: {PTranslatableCkeditor, PSelectKeyValue, TabFooter, PTreeTagInput, PTranslatableTextInput, PFRow},
  props: {
    data: {
      type: Object,
      required: true
    },
    isDeletable: {
      type: Boolean,
      required: true
    },
    advanced: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('eventGallery', [
      'tags',
      'gallery'
    ]),
    layoutOptions() {
      return galleryLayoutTypes.options()
    },
    sortingOptions() {
      return gallerySorting.options()
    },
  },
  data() {
    return {
      form: {
        name: {},
        description: {},
        is_free: false,
        is_public: false,
        show_in_all_photos: true,
        is_shareable: true,
        enhance_img: false,
        hide_name: false,
        passive: false,
        sorting: gallerySorting.default(),
        layout: galleryLayoutTypes.default(),
        order: 0,
        active: true,
        max_media: 0,
      },
    }
  },
  emits: ['save', 'delete'],
  async mounted() {
    await this.fetchTags()
    this.onReset()
  },
  methods: {
    ...mapActions('eventGallery', [
      'fetchTags',
      'fetchGallery',
      'fetchTabData'
    ]),
    ...mapActions('eventWizard', [
      'fetchEventConfiguration',
    ]),
    onReset() {
      this.form = {...this.data}
    },
    async onSave() {
      await this.emitPromised('save', this.form)
      await this.fetchEventConfiguration()
      await this.fetchGallery(this.gallery.id)
      await this.fetchTabData('setting')
      await this.$nextTick()
      this.onReset()
    },
    onDeleteGallery() {
      this.confirm({
        title: this.$pgettext('event_gallery.delete', 'Delete Gallery'),
        message: this.$pgettext('event_gallery.delete', 'Are you sure you want to delete this gallery?<br>This action cannot be undone'),
        cb_confirm: async () => {
          this.$emit('delete')
        }
      })
    }
  }
}
</script>