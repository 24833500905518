<template>
  <div>
    <h5 class="main-header mb-1" v-translate translate-context="event_gallery">Photo branding</h5>
     <p>
        <translate translate-context="event_wizard.label">
          Add a logo or banner that wil be applied at the bottom of every event photo
        </translate>
      </p>
    <hr class="mt-4">
    <LogoSetEditor
        in_tab
        :data="data"
        :get_upload_intent="get_upload_intent"
        :get_upload_intent_public_url="get_upload_intent_public_url"
        allow_empty
        @save="onSave"
        @updatePreview="$emit('preview',$event)"
    />
  </div>
</template>

<script>
import LogoSetEditor from "@/domain/logoSet/LogoSetEditor.vue";

export default {
  name: "TabLogoOnPhoto",
  components: {LogoSetEditor},
  props: {
    data: {
      type: Object,
      required: true
    },
    get_upload_intent: {
      type: Function,
      required: true
    },
    get_upload_intent_public_url: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      form: {},
    }
  },
  emits: ['save','preview'],
  methods: {
    async onSave(data) {
      await this.emitPromised('save', data)
    },
  }
}
</script>